.top-bar {
    @include make-row();
    width: 100%;
    // position: sticky;
    // top: 0;
    @extend .pt-1;
    @extend .pb-1;
    font-size: 0.673em;
    text-align: right;
    @extend .bg-primary-2;
    z-index: 9;
    height: 35px;
    overflow: hidden;
    .icon {
        @extend .mr-1;
    }
    svg.icon *{
        fill: none;
    }
    .info-email,
    .info-phone {
        .icon {
            margin-top: -.2em;
            height: 16px;
        }
        a {
            &:hover {
                color: $white;
                text-decoration: underline;
            }
        }
    }
    .info-phone {
        a {
            color: $white;
        }
    }
    .container {
    }
}

.process-circle {
    font-size: 1.5em;
    @extend .pb-1;
    width: 4.5rem;
    height: 4.5rem;
    font-family: $headings-font-family;
}

.img-responsive {
    width: 100%;
}