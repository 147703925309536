// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

@import 'components/accordion';
@import 'components/backgrounds';
@import 'components/basket';
@import 'components/buttons';
@import 'components/breadcrumb';
@import 'components/cards';
@import 'components/footer';
@import 'components/fonts';
@import 'components/forms';
@import 'components/header-section';
@import 'components/latestNews';
@import 'components/layout';
@import 'components/loader';
@import 'components/maps';
@import 'components/mixins';
@import 'components/modals';
@import 'components/navigation';
@import 'components/process';
@import 'components/section';
@import 'components/selectize';
@import 'components/selectize.default';
@import 'components/slider';
@import 'components/stoerer';
@import 'components/tables';
@import 'components/tabs';
@import 'components/typography';
@import 'components/utility';


$grid-gutter-widths: (
  sm: 20px,
  md: 30px,
  lg: 50px
);

@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
    @include media-breakpoint-up($grid-breakpoint) {
      $grid-gutter-half-width: $grid-gutter-width / 2;
      .row:not(.no-gutters) {
        margin-right: -$grid-gutter-half-width;
        margin-left: -$grid-gutter-half-width;
      }
      .row:not(.no-gutters) > .col,
      .row:not(.no-gutters) > [class*="col-"] {
        padding-right: $grid-gutter-half-width;
        padding-left: $grid-gutter-half-width;
      }
      .container {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
      }
    }
}

* {
    hyphens: auto;
}

body {
    background-repeat: no-repeat;
    background-size: contain;
    // background-position: top -15% center;
    // backdrop-filter: grayscale(100%);
    background-position-y: 0;
    
    &:before {
        content: '';
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba($white, 0) 0%, rgba($white, 1) 25%);
          background-color: $primary-4;
          mix-blend-mode: screen;
          display: block;
          position: absolute;
          top: 0;
          z-index: -1;
    }
}

.be-info {
    font-size: .75em;
    border: 2px solid $red;
    padding: .25em .5em;
    background-color: white;
    opacity: 0.7;
    position: absolute;
    z-index: 11;
}

// .bg-verlauf {
//     background: theme-color('primary');
//     background: linear-gradient(225deg, theme-color('primary-2') 0%, theme-color('primary') 100%);
// }

.story-kacheln {
    .card {
        border: none;
        @extend .hover-shadow-sm;
    }

    h3 {
        color: white;
    }

    .card-body {
        background: #efefef;
    }
}

.termine-kacheln,
.footer-form {

    .container {
        padding-bottom: 150px;
        @extend .layer-1;
    }

    @extend .banner-strap;
    @extend .pb-0;
    @extend .o-hidden;

    // @extend .bg-verlauf;

    // .card {
    //     border: none;
    //     // @extend .layer-1;
    //     // @extend .shadow-lg;
    // }

    .card-body {
        background-color: #fff;
    }
}

.footer-form {
    @extend .pt-0;
    @extend .mt-5;
}

// .bg-alternate {
//     .card-body {
//         background: theme-color('primary-2');

//         p {
//             @extend .text-white;
//         }
//     }
// }



.jonnitto-prettyembed-wrapper {
    border-radius: 0 !important;
    overflow: hidden;
}

.termine-kacheln,
.footer-form {

    h1,
    h2,
    h3 {
        @extend .text-light;
    }

    // @extend .bg-verlauf;
    // padding-bottom: 100px !important;
}

// .divider {
//     .bg-primary-4 {
//         fill: lighten(theme-color('primary-2'), 35%) !important;
//     }
// }



.rounded {
    border-radius: $border-radius !important;
    overflow: hidden;
}
img.rounded,
div.rounded{
    @include media-breakpoint-down (md) {
        border-radius: $border-radius !important;
    }
}


.jonnitto-image,
.jonnitto-prettyembed-wrapper {
    @extend p;
}


.carousel-cell {}



  .divider.flip-y.bg-primary-2 {
    svg {
        height: 75px;
        @include media-breakpoint-down (md) {
            height: 50px;
        }
        @include media-breakpoint-down (sm) {
            height: 20px;
        }
    }
  }

  .navbar {
    border-bottom: none;
    padding-top: $navbar-padding-y*1.9;
    &.scrolled.navbar-light {
        box-shadow: 0px 15px 30px 0px rgba($black,0.1);
    }
    svg.icon *{
        fill: none;
    }
  }

  .gradient-top, .gradient-bottom {
    .jarallax-img {
        filter: grayscale(100%);
    }
      
      &:before {
          content: '';
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba($white, 1) 0%, rgba($white, 0) 100%);
          background-color: $primary-4;
          mix-blend-mode: screen;
          display: block;
          position: absolute;
          top: 0;
      }
  }
  .gradient-bottom {
      &::before {
        background: linear-gradient(180deg, rgba($white, 0) 0%, rgba($white, 1) 100%);
        background-color: $primary-4;

      }
  }

  .divider-top {
      @extend .pt-7;
      border-top: 3px solid rgba($primary-3, .15) !important;
  }

  .modal-content {
      border-radius: 0;
      border: none;
      padding: 1rem;

      .row {
          margin-right: 0;
          margin-left: 0;
          padding-left: 0;
          padding-right: 0;
      }

      form {
          input, select, textarea {
              font-size: 1.125rem;
              color: theme-color(primary-3);
          }
      }
      
  }
  .selectize-dropdown {
    border: none;
}

.h-100 {
    min-height: 100px;
}
.h-200 {
    min-height: 200px;
}
.h-300 {
    min-height: 300px;
}
.h-400 {
    min-height: 400px;
}
.h-500 {
    min-height: 500px;
}
.h-600 {
    min-height: 600px;
}
.h-700 {
    min-height: 700px;
}
.h-800 {
    min-height: 800px;
}
.h-900 {
    min-height: 900px;
}
.h-1000 {
    min-height: 1000px;
}

[class*="h-"] {
    object-fit: cover;
}

.sticky-top {
    top: 0 !important;
}