.tab-element {
    .nav {
        // @extend .pb-2;
        @extend .border-bottom;
        border-color: #EDF2F5 !important;
        @extend .justify-content-start;
    }
    
    .nav-item {
        @extend .mx-0;
        @extend .pr-4;
        @extend .text-center;
        position: relative;
        top: 2px;
        
        .nav-link {
            @extend .px-0;
            @extend .border-bottom;
            
            transition: all 0.5s ease-out;
            opacity: 1 !important;
            .label {
                color: theme-color('primary-3') !important;
            }
            border-color: #EDF2F5 !important;
            &.active,
            &:hover {
                border-color: theme-color('primary-2') !important;
                .label {
                    color: theme-color('primary-2') !important;
                }
            }
        }

        .eq {
            height: 84px;
        }

        .label {
            @extend .h4;
            @extend .mt-3;
            // font-family: $font-family-sans-serif;
            // font-size: 1em;
            // color: theme-color('primary') !important;
        }
    }

    .tab-content {
        @extend .mt-4;
        border-color: theme-color('primary-3') !important;
        
    }

}

[data-isotope-filters] {
    @extend .border-bottom;
    @extend .mb-5;
    border-color: #EDF2F5 !important;
    border-width: $border-width*1.5 !important;

    .nav-item {
        @extend .mx-0;
        @extend .pr-4;
        @extend .text-center;
        @extend .h4;
        @extend .mb-0;
        position: relative;
        margin-bottom: -$border-width*1.5 !important;
        // top: 15px;
        
        .nav-link {
            @extend .px-0;
            @extend .pb-2;
            @extend .border-bottom;
            font-weight: 500 !important;
            border-color: #EDF2F5 !important;
            color: theme-color('primary-3');
            border-width: $border-width*1.5 !important;
            transition: all $transition-time ease-in-out;

            &.active,
            &:hover {
                cursor: pointer;
                border-color: theme-color('primary-2') !important;
                color: theme-color('primary-2') !important;
            }
        }
    }
 
    .badge {
        display: none;
    }

}