// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

$font-size-base:              1.3rem; // Assumes the browser default, typically `16px`


// Color Scheme
// Uncomment the variables below and change the hex values.

$primary:      #11AE6F;
$primary-2:     #333333;
$primary-3:     #999999;
$primary-4:     #3D2783;
$primary-5:     #F08536;
$primary-6:     #E5332A;

$light:         #efefef !default;
$dark:          #999999 !default;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "primary-4":  $primary-4,
    "primary-5":  $primary-5,
    "primary-6":  $primary-6,
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "primary-4":  $primary-4,
    "primary-5":  $primary-5,
    "primary-6":  $primary-6,

);

$link-color:  theme-color(primary);

$border-radius: 0px;
$border-width: 2px;
$border-color: $primary;

$font-family-sans-serif:      'sofia-pro', sans-serif;
$font-family-base:            'sofia-pro', sans-serif;
$headings-font-family:        'sofia-pro', sans-serif;

$font-weight-light:           400;
$font-weight-normal:          400;
$font-weight-medium:          700;
$font-weight-bold:            700;

$headings-font-weight:        700;
$display1-weight:             700;
$display2-weight:             700;
$display3-weight:             700;
$display4-weight:             700;


$breadcrumb-padding-y:              .5rem;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           .25rem;

$breadcrumb-margin-bottom:          0;

$breadcrumb-bg:                     $white;
$breadcrumb-divider-color:          $gray-600;
$breadcrumb-active-color:           $text-muted;
$breadcrumb-divider:                quote("/");

$breadcrumb-border-radius:          0;



$container-max-widths: (
  sm: 576px,
  md: 960px,
  lg: 1366px,
  xl: 1440px
);

$default-section-padding: $grid-gutter-width*4;