.breadcrumb {
    .breadcrumb-item {
        @extend .h4;

        .link-node {
            @extend .h4;
            @extend .mb-0;
        }
    }
}

.nav-line {
    ul {
        @extend .mb-0;
        @extend .pl-0;
        list-style-type: none;

        li {
            @extend .py-2;
            position: relative;
            border-top: 2px solid theme-color(primary-2);

            a {
                color: theme-color(primary-2);
                display: block;

                &:hover {
                    text-decoration: underline;
                }

                img {
                    float: right;
                }
            }
        }
    }

    &.nav-arrow {
        ul {
            li {
                &:after {
                    content: '';
                    width: 25px;
                    height: 25px;
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -12.5px;
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='25px' height='25px' viewBox='0 0 25 25' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E119B5B9A-D6FA-46BB-A740-88B99548E023%3C/title%3E%3Cg id='Home' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg transform='translate(-1296.000000, -3879.000000)' id='Immobilienmakler-finden' stroke='%2311AE6F' stroke-width='2'%3E%3Cg transform='translate(0.000000, 3658.000000)'%3E%3Cg id='Group-5' transform='translate(120.000000, 199.000000)'%3E%3Cg id='List/white' transform='translate(832.000000, 0.000000)'%3E%3Cg id='Group' transform='translate(344.000000, 22.000000)'%3E%3Cg id='arrow-right' transform='translate(2.000000, 5.000000)'%3E%3Cline x1='0' y1='7' x2='20' y2='7' id='Path'%3E%3C/line%3E%3Cpolyline id='Path' points='13 0 20 7 13 14'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }
        
        .bg-primary & {
            ul {
                li {
                    &:after {
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='25px' height='25px' viewBox='0 0 25 25' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E119B5B9A-D6FA-46BB-A740-88B99548E023%3C/title%3E%3Cg id='Home' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg transform='translate(-1296.000000, -3879.000000)' id='Immobilienmakler-finden' stroke='%23FFFFFF' stroke-width='2'%3E%3Cg transform='translate(0.000000, 3658.000000)'%3E%3Cg id='Group-5' transform='translate(120.000000, 199.000000)'%3E%3Cg id='List/white' transform='translate(832.000000, 0.000000)'%3E%3Cg id='Group' transform='translate(344.000000, 22.000000)'%3E%3Cg id='arrow-right' transform='translate(2.000000, 5.000000)'%3E%3Cline x1='0' y1='7' x2='20' y2='7' id='Path'%3E%3C/line%3E%3Cpolyline id='Path' points='13 0 20 7 13 14'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    }
                }
            }
        }

        .bg-primary &,
        .bg-primary-2 &,
        .bg-primary-3 &,
        .bg-primary-4 &,
        .bg-primary-5 &,
        .bg-primary-6 & {
            ul {

                li {
                    border-top: 2px solid theme-color(white);
                }
            }
        }
    }
}

.navbar-container {
    .dropdown-menu {
        .container {
            background: $dark;
            // width: 100vw;
            // margin-left: calc(-50vw + 50%);
            // position: relative;
        }
        color: $white;
    }

    background-color: $white;
    // top: 35px !important;
    // position: sticky;

    .position-fixed {
        // top: 35px !important;
        padding-top: 1rem !important;

    }

    @extend .layer-2;
    height: 120px;

    // background-color: $white !important;
    .navbar-dark.scrolled {}

    // font-family: $headings-font-family;
    // font-size: 1.25em !important;
    // letter-spacing: .5px;

    .navbar-toggler {
        .icon {
            height: 3rem;
        }
    }

    .navbar-brand {
        @include media-breakpoint-down(md) {
            max-width: 220px;
        }

        // @include media-breakpoint-down(sm) {
        //     max-width: 120px;
        // }
    }

    .nav-link {
        @extend .position-relative;
    }

    .first-level {
        &.nav-link {
            color: theme-color('primary-2');

            // &:hover {
            //     color: #f09;
            // }
        }
    }

    .nav-item {
        a {
            &.nav-link {
                font-weight: $font-weight-normal;
                white-space: nowrap;
                color: theme-color('primary-3') !important;
                
                

                &.is-active,
                &.is-currend {
                    color: theme-color('primary-2') !important;
                }
                // &:hover {
                //     color: theme-color('primary');
                // }
            }
        }

        &:hover,
        &.show {
            a {
                &.nav-link {
                    color: theme-color('primary-2') !important;
                }
            }
        }

        .icon {
            display: none;
        }

        .dropdown-grid-menu {
            @extend .py-1;
            border-radius: 0;
            border: none;
            background: white;
        }
    }

    .dropdown-grid-menu {
        @extend .p-0;
        min-width: 250px;
    }

    .dropdown-item {
        @extend .px-2;
        // @extend .py-1;

        &.multi-2,
        &.multi-3 {
            padding-left: 0 !important;

            &:hover {
                span {
                    color: $white;
                }
            }
        }

        font-weight: $font-weight-normal;
        font-size: 1em;
        // border-bottom: 1px dotted lighten(theme-color('primary-3'), 25%) !important;

        &.is-last {
            // border-bottom: none !important;
        }

        .icon {
            display: initial;
        }

        color: theme-color('primary-3');

        span {
            border-bottom: none;
        }

        &:hover {
            // background-color: theme-color('primary');
            color: $white;
            

            span {
                color: theme-color('primary-2');
            }

            &.is-current {
                color: $white !important;
            }
        }
    }
    h5 {
        font-weight: $font-weight-normal;
        font-size: 1em;
        color: $white;
        margin-bottom: 0;
        &:hover {
            color: $white;
        }
    }

    .is-active,
    .is-current {
        color: theme-color('primary-2') !important;

        // .bordered {
        //     @extend .pb-1;
        //     border-bottom: 2px solid theme-color('primary');

        //     @include media-breakpoint-down (md) {
        //         border: none;
        //     }
        // }

        // color: theme-color('primary') !important;

        &.dropdown-item {
            color: theme-color('primary') !important;
        }

    }

    .first-level {
        svg {
            transform: rotate(-90deg);
        }
    }

    .btn {
        position: relative;
        border: none;
        font-weight: $font-weight-normal;
        color: $white;

        &:hover {
            background: theme-color('primary-2') !important;
        }

    }


    .navToggle {
        background-color: none;

        svg {
            transform: rotate(-90deg);
        }
    }


    button {
        &.navbar-toggler {
            height: 100%;
            width: 5rem;
            border: 2px solid theme-color('primary') !important;
            border-radius: .325em;
            @extend .mt-2;

            @include media-breakpoint-down (sm) {
                margin-top: 0 !important;
            }
        }

        .navbar-toggler-open,
        .navbar-toggler-close {}

        .navbar-toggler-close {
            left: calc(50% - 1.5rem);
        }
    }
}

.nav-link {
    &.active {
        color: theme-color(primary);
    }
}

.navbar-brand img {
    transition: all .3s ease-in-out;

    .scrolled & {
        max-width: 75%;
    }

}


.no-icon {
    .nav-item {
        img {
            @extend .d-none;
        }
    }
}