.btn {
    white-space: nowrap;
    &-primary {
        @extend .bg-primary;
    }
    &-primary-2 {
        @extend .bg-primary-2;
    }
    &-primary-3 {
        @extend .bg-primary-3;
    }
    &-primary-4 {
        @extend .bg-primary-4;
    }
    &-primary-5 {
        @extend .bg-primary-5;
    }
    &-primary-6 {
        @extend .bg-primary-6;
    }
}

.btn,
.button {
    @extend .bg-primary;

    display: inline-block;
    border: none;
    position: relative;
    // text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: $font-weight-normal;
    letter-spacing: .01em !important;
    // background-color: theme-color(primary);
    padding: .3em 1em .5em !important;
    
    color: $white;
    border-radius: 0;
    cursor: pointer;
    transition: all .3s ease-in-out;
    z-index: +1;

    // &::after {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     width: 3em;
    //     margin-right: -1.5em;
    //     transform: skewX(-45deg);
    //     background-color: theme-color(primary);
    //     z-index: -1;
    //     transition: all .3s ease-in-out;

    // }

    &:hover {
        transform: translateX(.5rem);
        color: $white;
        background-color: $primary-2;

        &::after {
            background-color: theme-color(primary-2);
        }
    }
}

.link-external {
    &.btn {
        display: initial;
    }
}

// .button {
//     padding: .25rem .5rem;
//     // margin-bottom: 1rem;
//     background-color: theme-color(primary);
//     color: #FFF;

//     position: relative;
//     display: block;

//     width: 66%;

//     transition: all 0.3s;

//     &::after {
//         content: '';
//         display: inline-block;
//         background: theme-color(primary);

//         height: 100%;
//         width: 13%;
//         position: absolute;
//         z-index: -1;
//         right: -13%;
//         background-color: theme-color(primary);
//         top: 0px;

//         transition: all 0.3s;
//         transform: skew(-26deg);
//         transform-origin: top left;
//     }

//     &:hover {
//         color: $white;
//         background: darken(theme-color(primary), 5%);
//         &::after {
//             background: darken(theme-color(primary), 5%);
//             background-color: darken(theme-color(primary), 5%);
//         }
//     }
// }

// .btn-text {
//     width: 130%;
//     text-align: center
// }


// .btn {
//     @extend h4;
//     border-radius: 0;
//     font-family: $headings-font-family;
//     font-weight: $font-weight-medium;
//     font-size: $font-size-base;
//     color: white;
//     // text-transform: uppercase;



//     &:hover {
//         cursor: pointer;
//     }

//     position: relative;
//     @extend .layer-1;
//     overflow: visible !important;

//     &:after {
//         content: " ";
//         position: absolute;
//         display: block;
//         width: 100%;
//         height: 100%;
//         top: 0;
//         left: 0;
//         z-index: -1;
//         transform-origin: bottom left;
//         transform: skew(-45deg, 0deg);
//     }

//     &.btn {
//         &-primary {
//             color: $white;

//             &:after {
//                 background: theme-color(primary);
//             }
//         }

//         &-outline-primary {
//             border: $border-width solid $primary;
//             font-weight: 300;
//             color: $primary-2;
//         }
//     }
// }


.navbar {
    .btn {
        @extend .btn-primary;
        @extend .bg-primary;
        border: 2px solid theme-color('primary-2');
        // background: none;
        color: theme-color('primary');
    }
}
