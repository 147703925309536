
.basket {
    @extend .ml-2;
    @extend .mb-2;
    .badge {
        @extend .badge-pill;
        color: $white;
        margin-left: -.5rem;
        margin-top: -1rem;
        padding-left: 9px !important;
        padding-right: 9px !important;
    }
}