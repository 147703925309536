footer {
    // @extend .d-flex;
    @extend .py-0;

    .container {
        @extend .py-4;
        @extend .d-flex;
        @extend .flex-column;
        // min-height: 250px;
    }

    .footer-green {
        @extend .bg-primary;
        @extend .text-light;
        h2, .h2 {
            margin-bottom: 1em;
        }
        .container {
            @include media-breakpoint-up (md){
                padding-top: 5rem !important;
                padding-bottom: 5rem !important;
            }
        }
    }

    .footer-dark {
        @extend .bg-dark;
        @extend .text-light;
    }

    .divider {
        transform: scaleX(-1);
    }

    .navbar-brand {
        font-weight: $font-weight-bold;
    }

    .btn {
        color: color('white');
        font-weight: $font-weight-normal;
    }

    // font-family: $headings-font-family;
    // font-weight: $font-weight-bold;

    // background: theme-color('primary');
    color: color('white') !important;

    .footer-nav {
        @extend .nav;
        font-size: 1.25em !important;

        .nav-link {
            @extend .px-md-2;
        }

        .nav-item {
            @extend .d-none;
            @extend .d-md-block;

            &:last-child {
                a {
                    @extend .pr-0;
                }
            }
        }
    }

    .meta-nav {
        @extend .nav;
        // @extend .flex-column;

        .nav-item {
            position: relative;

            &:after {
                content: '/';
                position: absolute;
                top: 0.65em;
                left: -.125em;
                color: $primary-3;
            }

            &:first-child {
                .nav-link {
                    @extend .pl-0;
                }
                &:after {
                    content: '' !important;
                }
            }

            &:last-child {
                .nav-link {
                    @extend .pr-0;
                }
            }
        }

        .nav-link {
            @extend .pb-0;
            @extend .px-1;
            // text-decoration: underline;
            font-weight: 300 !important;
        }
    }

    .social-nav {
        @extend .nav;

        .nav-item {
            // @extend .col-sm-3;
            @extend .ml-2;

            a {
                background: white;
                display: block;
                width: 2em;
                height: 2em;
                color: theme-color('primary-2');
                position: relative;

                i {
                    margin: 0;
                    font-size: 1.5em;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-right: -50%;
                    transform: translate(-50%, -50%)
                }

                &:hover {
                    background: theme-color('primary-3');
                    // color: color('white');
                }
            }
        }
    }

    a {
        font-weight: $font-weight-normal;
        color: white;

        &:hover {
            color: white;
            text-decoration: underline;
        }
    }

    .nav {

        .link-app,
        .nav-link {
            color: $primary-3 !important;
            font-size: 1em;
            opacity: 1 !important;
            font-weight: 600;
        }
    }

    .font-regular {
        font-family: $font-family-base;
        font-size: 14px;
        font-weight: $font-weight-normal;

        .nav-link {
            font-weight: $font-weight-normal !important;
            font-size: 14px;
        }
    }

    .footer-dark {
        ul {
            @extend .pl-0;
            font-size: 0.75em !important;
            line-height: 1.75em;
            li {
                list-style-type: none;
            }
        }
        svg {
            &.icon:not([class*='bg-']) * {
                fill: none !important;
            }
        }
        .nav-item {
            // &:first-child {
            //     a {
            //         color: $white;
            //     }
            // }
            a {
                color: $primary-3;
                
            }
        }
        .info-email {
            a {
                color: $primary;
            }
        }
    }

    // background-color: theme-color('primary');

    // @extend .bg-verlauf;

    // @Julien, das wird wegen der Grafik oben evtl. nicht gehen.
    // background-image: linear-gradient(225deg, theme-color('primary'), theme-color('primary-1'));
}
