.image-trenner {
    padding-top: 0 !important;
    // padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    min-height: 550px;
    
    @extend .my-6;

    .divider {
        top: 0;
    }
}

.section-locations {
    @extend .mt-5;
    padding-top: 0 !important;

}

section {
    @include media-breakpoint-down (sm) {
        padding-top: $grid-gutter-width;
        padding-bottom: $grid-gutter-width;
    }
    .section {
        @extend .o-hidden;
    }
}
