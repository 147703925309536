.bg {
    &-primary {
        background-image: radial-gradient(circle at top right, #3CD296, theme-color(primary) 100%);
    }
    &-primary-2 {
        background-image: radial-gradient(circle at top right, #000000, theme-color(primary-2) 100%);
    }
    &-primary-3 {
        background-image: radial-gradient(circle at top right, #333333 0%, rgba(51,51,51,0.00) 100%);
    }
    &-primary-4 {
        background-image: radial-gradient(circle at top right, #9F8ED6, theme-color(primary-4) 100%);
    }
    &-primary-5 {
        background-image: radial-gradient(circle at top right, #FFC59B, theme-color(primary-5) 100%);
    }
    &-primary-6 {
        background-image: radial-gradient(circle at top right, #FF948F, theme-color(primary-6) 100%);
    }
    &-artikel {
        background: pink;
    }
}