.card {
    border: none;
    // min-height: 380px;
    overflow: hidden;
    border-radius: 0 !important;
    margin-bottom: $grid-gutter-width*3;
    @include media-breakpoint-down (md){
    margin-bottom: $grid-gutter-width;
    }
    @include media-breakpoint-down (sm){
    margin-bottom: $grid-gutter-width*2;
    }
    @extend .layer-1;
    // @extend .shadow-lg;

    min-height: 300px;

    .card-img-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-body {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        p.moreInfo {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    overflow: visible;

    .card-moreinfo {
        @extend .btn;
        @extend .bg-primary;
        display: inline;
        width: auto;
        position: absolute;
        @extend .py-1;
        @extend .px-2;
        bottom: .05em;
        margin-bottom: -1em;
    }

    .bg-light & {
        background-color: $white;
        .divider {
            polygon {
                fill: $white;
            }
        }
    }
    background-color: $light;
    .divider {
        polygon {
            fill: $light;
        }
    }
}




.card-img-top {
    .card_height {
        height: 180px;
    }
    .divider {
        svg {
            height: 40px;
        }
    }
}

.blogListItem {
    @extend .card;
    // @extend .bg-dark;
    @extend .text-light;
    @extend .hover-shadow-3d;
    // background-color: theme-color(primary-2) !important;
    transition: all $transition-time ease-in-out;

    .itemInfo {
        color: theme-color(primary-3);
        @extend .mb-2;
    }

    p.title {
        color: $white !important;
        @extend .mb-0;
        @extend .lead;
    }

    p.moreInfo {
        color: theme-color(primary) !important;
        @extend .lead;
    }
}

.blog-card {
    height: 460px !important;

    @include media-breakpoint-down(lg) {
        height: 300px !important;
    }

    .card-body {
        @extend .d-flex;
        @extend .flex-column;
    }
}

.iconBox {
    position: relative;
    @extend .card-body;
    @extend .text-center;
    @extend .bg-primary;
    @extend .text-white;
    display: block;
    @extend .mb-5;
    .teaser_icon {
        @extend .mb-3;
    }
    .teaser_header {
        @extend .h3;
        @extend .text-white;
        @extend .mb-0;
        // z-index: +1;
    }
    &.teaser-icon-button {
        @include media-breakpoint-down (sm) {
            max-height: 6rem;
            text-align: left !important;
            .teaser_icon {
                float: right;
                max-width: 50px;
                margin-top: -.75rem;
            }
        }
    }
    &.teaser-home-image {
        &:after {
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: radial-gradient(circle at bottom left, #333333 0%, rgba(51,51,51,0.00) 100%);
            z-index: -1;
            display: block;
        }
        img {
            z-index: -3;
        }
        background: none !important;
        .teaser_header {
            z-index: +1;
            margin-right: $grid-gutter-width*1.5;
            position: absolute;
            @extend .text-left;
            @extend .text-light;
            bottom: $grid-gutter-width*1.5;
            @include media-breakpoint-down (sm) {
                margin-right: $grid-gutter-width;
                bottom: $grid-gutter-width*.75;
            }
        }
    }
    &.big-teaser {
        padding: $grid-gutter-width*2 ;
        @include media-breakpoint-down (sm) {
            padding: $grid-gutter-width;
        }
    }
}