.breadcrumb__list {
    background: none !important;
    @extend .breadcrumb;

    .breadcrumb__element {
        @extend .breadcrumb-item;

        a {
            font-size: 1.25rem !important;
            text-transform: initial !important;
            font-family: $font-family-base !important;
            font-weight: $font-weight-normal !important;
            letter-spacing: 0 !important;
            opacity: 1 !important;
        }

        &::before {
            background-image: url(../img/icons/icon-breadcrumb-right.svg) !important;
            background-repeat: no-repeat;
            background-position: center center;
            transform: rotateZ(0deg) !important;
            opacity: 1 !important;
            margin-top: .2em;
        }

        .breadcrumb__link {
            color: theme-color(primary-3) !important;

            &.breadcrumb__link--active {
                &::before {}

                &.breadcrumb__link {
                    color: theme-color(primary-3) !important;
                }
            }

            &.breadcrumb__link--current,
            &:hover {
                &::before {
                    opacity: 1 !important;
                }

                &.breadcrumb__link {
                    color: theme-color(primary-2) !important;
                    opacity: 1 !important;
                }
            }
        }
    }
}