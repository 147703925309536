table {
    @extend .table;

    font-family: $headings-font-family;
    width: 100%;
    text-align: left;

    td {
        @extend .px-0;

        &:first-child {
            // color: red;
            min-width: 200px;
            font-weight: $font-weight-bold;
        }
    }

    &-links {
        a {
            font-weight: $font-weight-bold;
        }

        svg {
            transform: rotate(-180deg);
            display: none;
        }

        .hasLink {
            border-top: $border-width solid $primary-2;

            &.hover {
                cursor: pointer !important;
            }

            a {
                @extend h3;
                color: $primary-2;

                &:hover {
                    color: $primary;
                }
            }
        }

        .hasLink {
            svg {
                display: inline-block;
            }
        }
    }
}
